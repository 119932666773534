import React, { useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  InputLabel,
} from "@material-ui/core";
import "../Styles/signup.css";
import axios from "axios";
import {
  setUid,
  setName,
  setUserEmail,
  setIsTutorial,
} from "../state/app-slice";
import { useAppDispatch } from "../state/hooks/common.hooks";
import MainPageHeader from "../Components/MainPageHeader";

const SignUp = (props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);
  const [reRegisterWarning, setReRegisterWarning] = useState(false);
  const [loading, setLoading] = useState();
  const location = useLocation();

  const signupUser = async () => {
    setLoading("loading");
    await axios
      .post(process.env.REACT_APP_APILINK + "signup", {
        createdAt: new Date().toLocaleString(),
        displayName: displayName,
        email: email,
        password: password,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200) {
          setLoading("succeeded");
          dispatch(setName(data.user.firstName + " " + data.user.lastName));
          dispatch(setUid(data.user.id));
          dispatch(setUserEmail(data.user.email));
          dispatch(setIsTutorial(true));
          props.history.push("/Add-MatchMaker");
        } else if (data.status === 409) {
          setLoading("failed");
          setError(data.message);
        } else if (data.status === 411) {
          setLoading("failed");
          setError(data.message);
        }
      })
      .catch((error) => {
        setLoading("failed");
        setError("Error signing up with email and password" + error);
        setEmail("");
        setPassword("");
        setDisplayName("");
      });
  };

  const createUserWithEmailAndPasswordHandler = async (event) => {
    event.preventDefault();

    await signupUser();
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }
  };

  return (
    <div key={location.pathname}>
      <div className="main" style={{ zoom: "100%" }}>
        <MainPageHeader />

        <div className="form">
          <div
            className="mt-8"
            style={{
              backgroundColor: "white",
              borderRadius: "25px",
              padding: "30px",
            }}
          >
            <Typography
              variant="h2"
              display="block"
              align="center"
              id="profile-us"
              style={{
                paddingTop: "0",
                paddingBottom: "15px",
                margin: "0rem",
                fontSize: "2rem",
              }}
            >
              Shadchan Sign Up
            </Typography>
            {reRegisterWarning && (
              <div
                style={{
                  padding: "10px",
                  margin: "10px 0",
                  border: "1px solid transparent",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontFamily: "Arial",
                  backgroundColor: "#fff3cd",
                  borderColor: "#ffeeba",
                  color: "#856404",
                  width: "350px",
                }}
              >
                <span style={{ marginRight: "10px" }}>⚠️</span>
                <span>
                  We've made some updates to the platform. Please re-register
                  your information as a Shadchan.
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    padding: "0 8px",
                    fontWeight: "bold",
                  }}
                  onClick={() => setReRegisterWarning(false)}
                >
                  ×
                </span>
              </div>
            )}
            <div
              className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8"
              style={{ width: "40vh" }}
            >
              {error !== null && (
                <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
                  {error}
                </div>
              )}
              <form>
                <div className="form-row">
                  <div className="form-group col-md">
                    <InputLabel style={{ textAlign: "left" }}>
                      Display Name
                    </InputLabel>
                    <TextField
                      type="text"
                      name="displayName"
                      value={displayName}
                      placeholder="E.g: John"
                      id="displayName"
                      onChange={(event) => onChangeHandler(event)}
                      variant="outlined"
                      style={{ marginTop: 0, width: "100%" }}
                    />
                  </div>
                </div>
                <div className="form-row" style={{ textAlign: "center" }}>
                  <div className="form-group col-md">
                    <InputLabel style={{ textAlign: "left" }}>
                      Email Address:
                    </InputLabel>
                    <TextField
                      type="email"
                      id="userEmail"
                      name="userEmail"
                      variant="outlined"
                      onChange={(event) => onChangeHandler(event)}
                      placeholder="E.g: jdoe@gmail.com"
                      value={email}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="form-row" style={{ textAlign: "center" }}>
                  <div className="form-group col-md">
                    <InputLabel style={{ textAlign: "left" }}>
                      Password:
                    </InputLabel>
                    <TextField
                      type="password"
                      id="userPassword"
                      variant="outlined"
                      name="userPassword"
                      placeholder="Your Password"
                      onChange={(event) => onChangeHandler(event)}
                      value={password}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    className="btn btn-success"
                    disabled={loading === "loading"}
                    style={{
                      margin: 0,
                      width: "100%",
                      backgroundColor: "#90387E",
                      borderColor: "#90387E",
                      color: "#FFFFFF",
                    }}
                    onClick={(event) => {
                      createUserWithEmailAndPasswordHandler(
                        event,
                        displayName,
                        email,
                        password
                      );
                    }}
                  >
                    {loading === "loading" ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Sign up"
                    )}
                  </Button>
                </div>
              </form>

              <p className="text-center my-3">
                Already have an account?{" "}
             
                <Link
                  to="/sign-in"       
                  className="text-blue-500 hover:text-blue-600"
                  style={{ color: "#90387E" }}
                >
                  Sign in here.
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
