import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import LoginProfile from "./LoginProfile";
import AddMatchmaker from "../Pages/AddMatchmaker";
import PasswordReset from "./PasswordReset";
import { useAppSelector } from "../state/hooks/common.hooks";

function Application() {
  const user = useAppSelector((state) => state.app.uid);
  const location = useLocation();
  
  if (user) {
    return <LoginProfile />;
  }
  
  return (
    <div key={location.pathname}>
      <Switch>
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route path="/Add-MatchMaker" component={AddMatchmaker} />
        <Route path="/passwordReset" component={PasswordReset} />
        <Route path="/" component={SignUp} />
      </Switch>
    </div>
  );
}

export default Application;