import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Typist from "react-typist";
import Jpg from "../editable-stuff/home_logo.png";
import "../App";
import {
  setIsMatchesMadeTutorial,
  setIsMatchesPageTutorial,
  setIsSinglesPageTutorial,
  setIsTutorial,
} from "../state/app-slice";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import axios from "axios";

const MainPage = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [modalState, setModalState] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!appState.uid || appState.uid === "") {
      history.replace("/sign-up");
    }
  }, [history]);

  return (
    <div>
      <div
        id="mainHeader"
        className="jumbotron jumbotron-fluid bg-light text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap"
        style={{ marginBottom: 0 }}
      >
        <div
          style={{ marginTop: "25px" }}
          className="container container-fluid text-center"
        >
          <h3 className="display-3 text-dark">Shadchan App</h3>
          <Typist cursor={{ hideWhenDone: true }} className="lead text-dark">
            Automation for Matchmakers.
          </Typist>
          <br></br>
          <Link
            className="btn btn-outline-dark"
            role="button"
            to="/singles"
            style={{ borderColor: "#90387E", color: "#90387E" }}
          >
            Singles
          </Link>
          <Link
            className="btn btn-outline-dark"
            role="button"
            to="/matches"
            style={{ borderColor: "#90387E", color: "#90387E" }}
          >
            Matches
          </Link>
          <Link
            className="btn btn-outline-dark"
            role="button"
            to="/profile"
            style={{ borderColor: "#90387E", color: "#90387E" }}
          >
            My Profile
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
