import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { PhotoCamera, Save } from "@material-ui/icons";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { setSingleUid, setUid } from "../state/app-slice";
import { PLACEHOLDER_PROFILE_IMAGE } from "../state/constants";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function SinglesForm() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [title, setTitle] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [dob, setDOB] = React.useState(new Date("2000-01-01T01:01:01"));
  const [gapyear, setGapYear] = React.useState("");
  const [gapYearSelected, setGapYearSelected] = React.useState("");
  const [photoUrl, setPhotoUrl] = React.useState(PLACEHOLDER_PROFILE_IMAGE);
  const [imageLoading, setImageLoading] = React.useState("loading");
  const [progress, setProgress] = React.useState(0);
  const [locationBlacklist, setLocationBlacklist] = React.useState([]);
  const [ageRange, setAgeRange] = React.useState("");
  const [ageBlacklist, setAgeBlacklist] = React.useState("");
  const classes = useStyles();
  const history = useHistory();

  const traitQuestions = [
    { label: "Are you Quiet?", name: "quiet-radio" },
    { label: "Are you Outgoing?", name: "outgoing-radio" },
    { label: "Are you Funny?", name: "funny-radio" },
    { label: "Are you Intellectual?", name: "intellectual-radio" },
    { label: "Are you Athletic?", name: "athletic-radio" },
    { label: "Do you watch Movies/TV?", name: "movies-radio" },
    { label: "Do you learn Torah regularly?", name: "learn-radio" },
    { label: "Do you attend Minyan daily?", name: "minyan-radio" },
    { label: "Are you stringent with Halacha?", name: "stringent-radio" },
    { label: "Do you desire making Aliyah?", name: "aliyah-radio" },
  ];

  const preferenceQuestions = [
    { label: "Do you want someone Quiet?", name: "quiet-preference-radio" },
    {
      label: "Do you want someone Outgoing?",
      name: "outgoing-preference-radio",
    },
    { label: "Do you want someone Funny?", name: "funny-preference-radio" },
    {
      label: "Do you want someone Intellectual?",
      name: "intellectual-preference-radio",
    },
    {
      label: "Do you want someone Athletic?",
      name: "athletic-preference-radio",
    },
    {
      label: "Do you want someone who watches Movies/TV?",
      name: "movies-preference-radio",
    },
    {
      label: "Do you want someone who learns Torah regularly?",
      name: "learn-preference-radio",
    },
    {
      label: "Do you want someone who attends Minyan daily?",
      name: "minyan-preference-radio",
    },
    {
      label: "Do you want someone who is stringent with Halacha?",
      name: "stringent-preference-radio",
    },
    {
      label: "Do you want someone who desires making Aliyah?",
      name: "aliyah-preference-radio",
    },
  ];

  if (window.location.href.includes("single-sign-up")) {
    dispatch(
      setUid(
        window.location.href.substring(
          window.location.href.search("single-sign-up/") + 15
        )
      )
    );
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (date) => {
    setDOB(date);
  };

  const handleGapYearChange = (event) => {
    setGapYear(event.target.value);
    setGapYearSelected(event.target.value);
    if (event.target.value === "No") {
      document.getElementById("gapyearschool").removeAttribute("required");
      document.getElementById("red").innerHTML = "";
    } else {
      document.getElementById("gapyearschool").setAttribute("required", "");
      document.getElementById("red").innerHTML = "*";
    }
  };

  const handlePhotoChange = async (event) => {
    event.preventDefault();
    setImageLoading("loading");
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("singleId", appState.singleuid);
    await axios
      .post(process.env.REACT_APP_APILINK + "uploadSinglesPhoto", formData)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200 && data.photoUrl) {
          setTimeout(async () => {
            setPhotoUrl(data.photoUrl);
            setImageLoading("succeeded");
          }, 1000);
        } else {
          setImageLoading("failed");
          alert("Image failed to upload. Try again.");
        }
      });
  };

  const handleLocationChange = (event) => {
    setLocationBlacklist(event.target.value);
  };

  const handleAgeRangeChange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleAgeBlacklistChange = (event) => {
    setAgeBlacklist(event.target.value);
  };

  const initializeForm = () => {
    const page = window.location.href;
    if (
      page.includes("editable=false") ||
      page.includes("sign-in") ||
      page.includes("signUp")
    ) {
      document.getElementById("save").style.display = "none";
      document.getElementById("delete").style.display = "none";
    }

    if (page.includes("editable=false") || !page.includes("sign-in")) {
    }

    if (appState.singleuid) {
      const promise = axios
        .get(
          process.env.REACT_APP_APILINK +
            "getSinglesProfile/" +
            appState.singleuid
        )
        .then(function (response) {
          //window.alert(response.data.firstName);
          if (response.data.photoUrl) {
            setPhotoUrl(response.data.photoUrl);
            setImageLoading("succeeded");
          } else {
            setImageLoading("succeeded");
          }
          document.getElementById("firstname").value = response.data.firstName;
          document.getElementById("lastname").value = response.data.lastName;
          document.getElementById("city").value = response.data.city;
          document.getElementById("email").value = response.data.email;
          // document.getElementById("email").readOnly = true;
          document.getElementById("number").value = response.data.cellPhone;
          document.getElementById("heightinches").value =
            response.data.heightinches;
          document.getElementById("heightfeet").value =
            response.data.heightfeet;
          document.getElementById("gapyearschool").value =
            response.data.gapyearschool;
          //var date = new Date(response.data.dob.seconds * 1000);
          //setDOB(date);
          document.getElementById("highschool").value =
            response.data.highSchool;
          document.getElementById("graduate").value =
            response.data.graduateSchool;
          document.getElementById("graduate-degree").value =
            response.data.graduateDegree;
          document.getElementById("college").value = response.data.college;
          document.getElementById("degree").value = response.data.degree;
          document.getElementById("synagogue").value = response.data.shul;
          //document.getElementById("path").value = response.data.photoURL;
          document.getElementById("description").value =
            response.data.description;
          //document.getElementById("description-main").innerHTML = response.data.description;
          //document.getElementById("last-updated").innerHTML = "Last Updated " +new Date(response.data.createdAt.seconds);
          //document.getElementById("name-main").innerHTML = response.data.firstName + " " + response.data.lastName;

          setTitle(response.data.title);
          setState(response.data.state);
          setCountry(response.data.country);
          setGender(response.data.gender);
          setGapYear(response.data.gapYear);
          setDOB(response.data.dob);

          if (response.data.traits != null) {
            document.getElementById(
              "quiet-radio" + response.data.traits.charAt(0)
            ).checked = true;
            document.getElementById(
              "outgoing-radio" + response.data.traits.charAt(1)
            ).checked = true;
            document.getElementById(
              "funny-radio" + response.data.traits.charAt(2)
            ).checked = true;
            document.getElementById(
              "intellectual-radio" + response.data.traits.charAt(3)
            ).checked = true;
            document.getElementById(
              "athletic-radio" + response.data.traits.charAt(4)
            ).checked = true;
            document.getElementById(
              "movies-radio" + response.data.traits.charAt(5)
            ).checked = true;
            document.getElementById(
              "learn-radio" + response.data.traits.charAt(6)
            ).checked = true;
            document.getElementById(
              "minyan-radio" + response.data.traits.charAt(7)
            ).checked = true;
            document.getElementById(
              "stringent-radio" + response.data.traits.charAt(8)
            ).checked = true;
            document.getElementById(
              "aliyah-radio" + response.data.traits.charAt(9)
            ).checked = true;
            document.getElementById(
              "quiet-preference-radio" + response.data.preferences.charAt(0)
            ).checked = true;
            document.getElementById(
              "outgoing-preference-radio" + response.data.preferences.charAt(1)
            ).checked = true;
            document.getElementById(
              "funny-preference-radio" + response.data.preferences.charAt(2)
            ).checked = true;
            document.getElementById(
              "intellectual-preference-radio" +
                response.data.preferences.charAt(3)
            ).checked = true;
            document.getElementById(
              "athletic-preference-radio" + response.data.preferences.charAt(4)
            ).checked = true;
            document.getElementById(
              "movies-preference-radio" + response.data.preferences.charAt(5)
            ).checked = true;
            document.getElementById(
              "learn-preference-radio" + response.data.preferences.charAt(6)
            ).checked = true;
            document.getElementById(
              "minyan-preference-radio" + response.data.preferences.charAt(7)
            ).checked = true;
            document.getElementById(
              "stringent-preference-radio" + response.data.preferences.charAt(8)
            ).checked = true;
            document.getElementById(
              "aliyah-preference-radio" + response.data.preferences.charAt(9)
            ).checked = true;
          }
          if (response.data.blacklist != null) {
            if (response.data.blacklist.age != null) {
              setAgeRange(response.data.blacklist.age.charAt(0));
              setAgeBlacklist(response.data.blacklist.age.substring(1));
            }
            if (response.data.blacklist.location != null)
              setLocationBlacklist(response.data.blacklist.location);
          }
          // document.getElementById("email").readOnly = true;
          // document.getElementById("email").style.backgroundColor = "lightgrey";
        });
    } else {
      const randomUuid = uuidv4();
      setImageLoading("succeeded");
      dispatch(setSingleUid(randomUuid));
    }
  };

  useEffect(() => {
    initializeForm();
    return () => {
      dispatch(setSingleUid(""));
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firstName = document.getElementById("firstname").value;
    const lastName = document.getElementById("lastname").value;
    const city = document.getElementById("city").value;
    const email = document.getElementById("email").value;
    const number = document.getElementById("number").value;
    const dob = document.getElementById("dob").value;
    const heightinches = document.getElementById("heightinches").value;
    const heightfeet = document.getElementById("heightfeet").value;
    const highschool = document.getElementById("highschool").value;
    const college = document.getElementById("college").value;
    const degree = document.getElementById("degree").value;
    const graduate = document.getElementById("graduate").value;
    const graduateDegree = document.getElementById("graduate-degree").value;
    const gapyearschool = document.getElementById("gapyearschool").value;
    const synagogue = document.getElementById("synagogue").value;
    // const path = document.getElementById("path").value;
    const description = document.getElementById("description").value;
    const quiet = document.querySelector(
      'input[name="quiet-radio"]:checked'
    ).value;
    const outgoing = document.querySelector(
      'input[name="outgoing-radio"]:checked'
    ).value;
    const funny = document.querySelector(
      'input[name="funny-radio"]:checked'
    ).value;
    const intellectual = document.querySelector(
      'input[name="intellectual-radio"]:checked'
    ).value;
    const athletic = document.querySelector(
      'input[name="athletic-radio"]:checked'
    ).value;
    const movies = document.querySelector(
      'input[name="movies-radio"]:checked'
    ).value;
    const learn = document.querySelector(
      'input[name="learn-radio"]:checked'
    ).value;
    const minyan = document.querySelector(
      'input[name="minyan-radio"]:checked'
    ).value;
    const stringent = document.querySelector(
      'input[name="stringent-radio"]:checked'
    ).value;
    const aliyah = document.querySelector(
      'input[name="aliyah-radio"]:checked'
    ).value;
    const quietPref = document.querySelector(
      'input[name="quiet-preference-radio"]:checked'
    ).value;
    const outgoingPref = document.querySelector(
      'input[name="outgoing-preference-radio"]:checked'
    ).value;
    const funnyPref = document.querySelector(
      'input[name="funny-preference-radio"]:checked'
    ).value;
    const intellectualPref = document.querySelector(
      'input[name="intellectual-preference-radio"]:checked'
    ).value;
    const athleticPref = document.querySelector(
      'input[name="athletic-preference-radio"]:checked'
    ).value;
    const moviesPref = document.querySelector(
      'input[name="movies-preference-radio"]:checked'
    ).value;
    const learnPref = document.querySelector(
      'input[name="learn-preference-radio"]:checked'
    ).value;
    const minyanPref = document.querySelector(
      'input[name="minyan-preference-radio"]:checked'
    ).value;
    const stringentPref = document.querySelector(
      'input[name="stringent-preference-radio"]:checked'
    ).value;
    const aliyahPref = document.querySelector(
      'input[name="aliyah-preference-radio"]:checked'
    ).value;

    const traits =
      quiet +
      "" +
      outgoing +
      "" +
      funny +
      "" +
      intellectual +
      "" +
      athletic +
      "" +
      movies +
      "" +
      learn +
      "" +
      minyan +
      "" +
      stringent +
      "" +
      aliyah;
    const preferences =
      quietPref +
      "" +
      outgoingPref +
      "" +
      funnyPref +
      "" +
      intellectualPref +
      "" +
      athleticPref +
      "" +
      moviesPref +
      "" +
      learnPref +
      "" +
      minyanPref +
      "" +
      stringentPref +
      "" +
      aliyahPref;

    const payload = {
      docId: appState.singleuid,
      title: title,
      gapyearschool: gapyearschool,
      heightinches: heightinches,
      heightfeet: heightfeet,
      firstName: firstName,
      lastName: lastName,
      city: city,
      state: state,
      country: country,
      email: email,
      cellPhone: number,
      gender: gender,
      dob: dob,
      highSchool: highschool,
      gapYear: gapyear,
      college: college,
      degree: degree,
      graduateSchool: graduate,
      graduateDegree: graduateDegree,
      shul: synagogue,
      photoUrl: photoUrl,
      description: description,
      traits: traits,
      preferences: preferences,
      shadchanId: appState.uid,
      blacklist: {
        location: locationBlacklist,
        age: ageRange + ageBlacklist,
      },
    };
    const promise = await axios
      .post(process.env.REACT_APP_APILINK + "setSinglesProfile", payload)
      .then(function (response) {
        if (!response.data.id) {
          window.alert("Something went wrong");
        } else {
          window.alert("Single Successfully Saved!");
          if (window.location.href.includes("single-sign-up")) {
            history.replace("/success");
          } else {
            history.replace("/singles");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Single
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => handleSubmit(e)}
          id="single-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div
            className="row no-gutters"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-3">
              {imageLoading === "loading" ? (
                <div
                  style={{
                    marginTop: "0px",
                    height: "250px",
                    width: "250px",
                  }}
                >
                  <CircularProgress style={{ marginTop: "50%" }} />
                </div>
              ) : (
                <img
                  src={`${photoUrl}?time=${new Date().getTime()}}`}
                  className="img-responsive img-thumbnail rounded-circle mx-auto"
                  style={{
                    marginTop: "0px",
                    height: "250px",
                    width: "250px",
                  }}
                  alt="Profile Pic"
                  id="profile-image"
                />
              )}
              <FormControl className="row-md-3">
                <label style={{ width: "100%" }} htmlFor="upload-photo">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="upload-photo"
                    type="file"
                    onChange={handlePhotoChange}
                  />
                  <Button
                    required
                    size="medium"
                    style={{
                      color: "#90387E",
                      borderColor: "#90387E",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    variant="outlined"
                    component="span"
                    startIcon={<PhotoCamera />}
                  >
                    Upload Photo
                  </Button>
                </label>
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Title <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={title}
                onChange={handleTitleChange}
                required
                type="text"
                variant="outlined"
                id="title"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Title</em>
                </MenuItem>
                <MenuItem value={"Ms"}>Ms</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Dr"}>Dr</MenuItem>
                <MenuItem value={"Prof"}>Prof</MenuItem>
                <MenuItem value={"Sir"}>Sir</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                First Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                variant="outlined"
                id="firstname"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Last Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="lastname"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                City<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="city"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                State<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={state}
                onChange={handleStateChange}
                required
                type="text"
                variant="outlined"
                id="state"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select State</em>
                </MenuItem>
                <MenuItem value={"AL"}>Alabama</MenuItem>
                <MenuItem value={"AK"}>Alaska</MenuItem>
                <MenuItem value={"AZ"}>Arizona</MenuItem>
                <MenuItem value={"AR"}>Arkansas</MenuItem>
                <MenuItem value={"CA"}>California</MenuItem>
                <MenuItem value={"CO"}>Colorado</MenuItem>
                <MenuItem value={"CT"}>Connecticut</MenuItem>
                <MenuItem value={"DE"}>Delaware</MenuItem>
                <MenuItem value={"FL"}>Florida</MenuItem>
                <MenuItem value={"GA"}>Georgia</MenuItem>
                <MenuItem value={"HI"}>Hawaii</MenuItem>
                <MenuItem value={"ID"}>Idaho</MenuItem>
                <MenuItem value={"IL"}>Illinois</MenuItem>
                <MenuItem value={"IN"}>Indiana</MenuItem>
                <MenuItem value={"IA"}>Iowa</MenuItem>
                <MenuItem value={"KS"}>Kansas</MenuItem>
                <MenuItem value={"KY"}>Kentucky</MenuItem>
                <MenuItem value={"LA"}>Louisiana</MenuItem>
                <MenuItem value={"ME"}>Maine</MenuItem>
                <MenuItem value={"MD"}>Maryland</MenuItem>
                <MenuItem value={"MA"}>Massachusetts</MenuItem>
                <MenuItem value={"MI"}>Michigan</MenuItem>
                <MenuItem value={"MN"}>Minnesota</MenuItem>
                <MenuItem value={"MS"}>Mississippi</MenuItem>
                <MenuItem value={"MO"}>Missouri</MenuItem>
                <MenuItem value={"MT"}>Montana</MenuItem>
                <MenuItem value={"NE"}>Nebraska</MenuItem>
                <MenuItem value={"NV"}>Nevada</MenuItem>
                <MenuItem value={"NH"}>New Hampshire</MenuItem>
                <MenuItem value={"NJ"}>New Jersey</MenuItem>
                <MenuItem value={"NM"}>New Mexico</MenuItem>
                <MenuItem value={"NY"}>New York</MenuItem>
                <MenuItem value={"NC"}>North Carolina</MenuItem>
                <MenuItem value={"ND"}>North Dakota</MenuItem>
                <MenuItem value={"OH"}>Ohio</MenuItem>
                <MenuItem value={"OK"}>Oklahoma</MenuItem>
                <MenuItem value={"OR"}>Oregon</MenuItem>
                <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                <MenuItem value={"RI"}>Rhode Island</MenuItem>
                <MenuItem value={"SC"}>South Carolina</MenuItem>
                <MenuItem value={"SD"}>South Dakota</MenuItem>
                <MenuItem value={"TN"}>Tennessee</MenuItem>
                <MenuItem value={"TX"}>Texas</MenuItem>
                <MenuItem value={"UT"}>Utah</MenuItem>
                <MenuItem value={"VT"}>Vermont</MenuItem>
                <MenuItem value={"VA"}>Virginia</MenuItem>
                <MenuItem value={"WA"}>Washington</MenuItem>
                <MenuItem value={"WV"}>West Virginia</MenuItem>
                <MenuItem value={"WI"}>Wisconsin</MenuItem>
                <MenuItem value={"WY"}>Wyoming</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Country <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={country}
                onChange={handleCountryChange}
                required
                type="text"
                variant="outlined"
                id="country"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Country</em>
                </MenuItem>
                <MenuItem value={"United States"}>United States</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-7">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="email"
                id="email"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Phone Number <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="tel"
                id="number"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Gender <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={gender}
                onChange={handleGenderChange}
                required
                type="text"
                variant="outlined"
                id="gender"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Gender</em>
                </MenuItem>
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Date of Birth <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="dob"
                  value={dob}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Height(feet) <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="number"
                id="heightfeet"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Height(inches) <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="number"
                id="heightinches"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                High School <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="highschool"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md-3">
              <InputLabel style={{ textAlign: "left" }}>
                Israel Gap Year <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={gapyear}
                onChange={handleGapYearChange}
                required
                type="text"
                variant="outlined"
                id="gapyear"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Did you take an Israel gap year?</em>
                </MenuItem>
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
                <MenuItem value={"Only a few months"}>Only few months</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Israel Gap Year School{" "}
                <span id="red" style={{ color: "red" }}></span>
              </InputLabel>
              <TextField
                type="text"
                id="gapyearschool"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Synagogue <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="synagogue"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Undergraduate School{" "}
              </InputLabel>
              <TextField
                type="text"
                id="college"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Undergraduate Major (if completed)
              </InputLabel>
              <TextField
                type="text"
                id="degree"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Graduate School{" "}
              </InputLabel>
              <TextField
                type="text"
                id="graduate"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Graduate Degree (if completed)
              </InputLabel>
              <TextField
                type="text"
                id="graduate-degree"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            {/* <div className="form-group col-md-2.5">
              <InputLabel style={{textAlign: "left"}}>Profile Picture <span style={{color:"red"}}>*</span></InputLabel>
              <label style={{ width: "100%"}} htmlFor="upload-photo">
                <input onChange={handlePhotoChange} accept="image/*" style={{ display: 'none'}} id="upload-photo" type="file"/>
                <Button required style={{ width: "100%", height: "56px"}} color="secondary" variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Photo</Button>
              </label>
            </div>
            <div className="form-group col-md-6">
              <InputLabel style={{textAlign: "left"}}>Selected Photo Path<span style={{color:"red"}}>*</span></InputLabel>
              <TextField disabled required type="text" id="path" variant="outlined" style={{ width: "100%", backgroundColor: "lightgrey" }} />
                </div> */}
          </div>
          <div className="form-group">
            <InputLabel style={{ textAlign: "left" }}>
              Description/Bio <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text-area"
              id="description"
              multiline
              minRows={4}
              variant="outlined"
              inputProps={{ maxLength: 500 }}
              style={{ width: "100%" }}
            />
          </div>
          <Typography
            variant="h2"
            display="block"
            align="center"
            id="reference-us"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              margin: "0rem",
              fontSize: "2.5rem",
            }}
          >
            Personality Traits
          </Typography>
          <table className="table-striped w-full rounded-md">
            <thead>
              <tr
                style={{
                  width: "100%",
                  height: "3rem",
                  position: "sticky",
                  top: "70px",
                  background: "rgb(144, 56, 126)",
                }}
              >
                <th scope="col">Question</th>
                <th scope="col">Yes</th>
                <th scope="col">In Between</th>
                <th scope="col">No</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Are you Quiet?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`quiet-radio${score}`}
                        name="quiet-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Are you Outgoing?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`outgoing-radio${score}`}
                        name="outgoing-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Are you Funny?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`funny-radio${score}`}
                        name="funny-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Are you Intellectual?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`intellectual-radio${score}`}
                        name="intellectual-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Are you Athletic?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`athletic-radio${score}`}
                        name="athletic-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you watch Movies/TV?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`movies-radio${score}`}
                        name="movies-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you learn Torah regularly?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`learn-radio${score}`}
                        name="learn-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you attend Minyan daily?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`minyan-radio${score}`}
                        name="minyan-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Are you stringent with Halacha?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`stringent-radio${score}`}
                        name="stringent-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you desire making Aliyah?</th>
                {[1, 2, 3].map((score) => {
                  return (
                    <td>
                      <input
                        value={score}
                        type="radio"
                        id={`aliyah-radio${score}`}
                        name="aliyah-radio"
                        required
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <Typography
            variant="h2"
            display="block"
            align="center"
            id="reference-us"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              margin: "0rem",
              fontSize: "2.5rem",
            }}
          >
            Personality Preferences
          </Typography>
          <table className="w-full table-striped overflow-x-auto">
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: "70px",
                  background: "rgb(144, 56, 126)",
                }}
              >
                <th scope="col">Question</th>
                <th scope="col">Yes</th>
                <th scope="col">In Between</th>
                <th scope="col">No</th>
                <th scope="col">Definitely Yes</th>
                <th scope="col">Definitely No</th>
                <th scope="col">Doesn't Matter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Do you want someone Quiet?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`quiet-preference-radio${score}`}
                        name="quiet-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you want someone Outgoing?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`outgoing-preference-radio${score}`}
                        name="outgoing-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you want someone Funny?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`funny-preference-radio${score}`}
                        name="funny-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you want someone Intellectual?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`intellectual-preference-radio${score}`}
                        name="intellectual-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you want someone Athletic?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`athletic-preference-radio${score}`}
                        name="athletic-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">Do you want someone who watches Movies/TV?</th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`movies-preference-radio${score}`}
                        name="movies-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">
                  Do you want someone who learns Torah regularly?
                </th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`learn-preference-radio${score}`}
                        name="learn-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">
                  Do you want someone who attends Minyan daily?
                </th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`minyan-preference-radio${score}`}
                        name="minyan-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">
                  Do you want someone who is stringent with Halacha?
                </th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`stringent-preference-radio${score}`}
                        name="stringent-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th scope="row">
                  Do you want someone who desires making Aliyah?
                </th>
                {[1, 2, 3, 0, 4, 5].map((score) => {
                  return (
                    <td>
                      <input
                        required
                        type="radio"
                        value={score}
                        id={`aliyah-preference-radio${score}`}
                        name="aliyah-preference-radio"
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <Typography
            variant="h2"
            display="block"
            align="center"
            id="reference-us"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              margin: "0rem",
              fontSize: "2.5rem",
            }}
          >
            Blacklist
          </Typography>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Location to not be matched with:
              </InputLabel>
              <Select
                value={locationBlacklist}
                onChange={handleLocationChange}
                multiple
                type="text"
                variant="outlined"
                id="location"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select location</em>
                </MenuItem>
                <MenuItem value={"AL"}>Alabama</MenuItem>
                <MenuItem value={"AK"}>Alaska</MenuItem>
                <MenuItem value={"AZ"}>Arizona</MenuItem>
                <MenuItem value={"AR"}>Arkansas</MenuItem>
                <MenuItem value={"CA"}>California</MenuItem>
                <MenuItem value={"CO"}>Colorado</MenuItem>
                <MenuItem value={"CT"}>Connecticut</MenuItem>
                <MenuItem value={"DE"}>Delaware</MenuItem>
                <MenuItem value={"FL"}>Florida</MenuItem>
                <MenuItem value={"GA"}>Georgia</MenuItem>
                <MenuItem value={"HI"}>Hawaii</MenuItem>
                <MenuItem value={"ID"}>Idaho</MenuItem>
                <MenuItem value={"IL"}>Illinois</MenuItem>
                <MenuItem value={"IN"}>Indiana</MenuItem>
                <MenuItem value={"IA"}>Iowa</MenuItem>
                <MenuItem value={"KS"}>Kansas</MenuItem>
                <MenuItem value={"KY"}>Kentucky</MenuItem>
                <MenuItem value={"LA"}>Louisiana</MenuItem>
                <MenuItem value={"ME"}>Maine</MenuItem>
                <MenuItem value={"MD"}>Maryland</MenuItem>
                <MenuItem value={"MA"}>Massachusetts</MenuItem>
                <MenuItem value={"MI"}>Michigan</MenuItem>
                <MenuItem value={"MN"}>Minnesota</MenuItem>
                <MenuItem value={"MS"}>Mississippi</MenuItem>
                <MenuItem value={"MO"}>Missouri</MenuItem>
                <MenuItem value={"MT"}>Montana</MenuItem>
                <MenuItem value={"NE"}>Nebraska</MenuItem>
                <MenuItem value={"NV"}>Nevada</MenuItem>
                <MenuItem value={"NH"}>New Hampshire</MenuItem>
                <MenuItem value={"NJ"}>New Jersey</MenuItem>
                <MenuItem value={"NM"}>New Mexico</MenuItem>
                <MenuItem value={"NY"}>New York</MenuItem>
                <MenuItem value={"NC"}>North Carolina</MenuItem>
                <MenuItem value={"ND"}>North Dakota</MenuItem>
                <MenuItem value={"OH"}>Ohio</MenuItem>
                <MenuItem value={"OK"}>Oklahoma</MenuItem>
                <MenuItem value={"OR"}>Oregon</MenuItem>
                <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                <MenuItem value={"RI"}>Rhode Island</MenuItem>
                <MenuItem value={"SC"}>South Carolina</MenuItem>
                <MenuItem value={"SD"}>South Dakota</MenuItem>
                <MenuItem value={"TN"}>Tennessee</MenuItem>
                <MenuItem value={"TX"}>Texas</MenuItem>
                <MenuItem value={"UT"}>Utah</MenuItem>
                <MenuItem value={"VT"}>Vermont</MenuItem>
                <MenuItem value={"VA"}>Virginia</MenuItem>
                <MenuItem value={"WA"}>Washington</MenuItem>
                <MenuItem value={"WV"}>West Virginia</MenuItem>
                <MenuItem value={"WI"}>Wisconsin</MenuItem>
                <MenuItem value={"WY"}>Wyoming</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Age Range to not be matched with:
              </InputLabel>
              <Select
                value={ageRange}
                onChange={handleAgeRangeChange}
                type="text"
                variant="outlined"
                id="ageRange"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Range</em>
                </MenuItem>
                <MenuItem value={"<"}>Less than</MenuItem>
                <MenuItem value={">"}>Greater than</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Age:</InputLabel>
              <Select
                value={ageBlacklist}
                onChange={handleAgeBlacklistChange}
                type="text"
                variant="outlined"
                id="ageBlacklist"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Age:</em>
                </MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={26}>26</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={28}>28</MenuItem>
                <MenuItem value={29}>29</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={31}>31</MenuItem>
                <MenuItem value={32}>32</MenuItem>
                <MenuItem value={33}>33</MenuItem>
                <MenuItem value={34}>34</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={37}>37</MenuItem>
                <MenuItem value={38}>38</MenuItem>
                <MenuItem value={39}>39</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={41}>41</MenuItem>
                <MenuItem value={42}>42</MenuItem>
                <MenuItem value={43}>43</MenuItem>
                <MenuItem value={44}>44</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={46}>46</MenuItem>
                <MenuItem value={47}>47</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={49}>49</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={51}>51</MenuItem>
                <MenuItem value={52}>52</MenuItem>
                <MenuItem value={53}>53</MenuItem>
                <MenuItem value={54}>54</MenuItem>
                <MenuItem value={55}>55</MenuItem>
                <MenuItem value={56}>56</MenuItem>
                <MenuItem value={57}>57</MenuItem>
                <MenuItem value={58}>58</MenuItem>
                <MenuItem value={59}>59</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={61}>61</MenuItem>
                <MenuItem value={62}>62</MenuItem>
                <MenuItem value={63}>63</MenuItem>
                <MenuItem value={64}>64</MenuItem>
                <MenuItem value={65}>65</MenuItem>
                <MenuItem value={66}>66</MenuItem>
                <MenuItem value={67}>67</MenuItem>
                <MenuItem value={68}>68</MenuItem>
                <MenuItem value={69}>69</MenuItem>
                <MenuItem value={70}>70</MenuItem>
                <MenuItem value={71}>71</MenuItem>
                <MenuItem value={72}>72</MenuItem>
                <MenuItem value={73}>73</MenuItem>
                <MenuItem value={74}>74</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={76}>76</MenuItem>
                <MenuItem value={77}>77</MenuItem>
                <MenuItem value={78}>78</MenuItem>
                <MenuItem value={79}>79</MenuItem>
                <MenuItem value={80}>80</MenuItem>
                <MenuItem value={81}>81</MenuItem>
                <MenuItem value={82}>82</MenuItem>
                <MenuItem value={83}>83</MenuItem>
                <MenuItem value={84}>84</MenuItem>
                <MenuItem value={85}>85</MenuItem>
                <MenuItem value={86}>86</MenuItem>
                <MenuItem value={87}>87</MenuItem>
                <MenuItem value={88}>88</MenuItem>
                <MenuItem value={89}>89</MenuItem>
                <MenuItem value={90}>90</MenuItem>
                <MenuItem value={91}>91</MenuItem>
                <MenuItem value={92}>92</MenuItem>
                <MenuItem value={93}>93</MenuItem>
                <MenuItem value={94}>94</MenuItem>
                <MenuItem value={95}>95</MenuItem>
                <MenuItem value={96}>96</MenuItem>
                <MenuItem value={97}>97</MenuItem>
                <MenuItem value={98}>98</MenuItem>
                <MenuItem value={99}>99</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              style={{ backgroundColor: "#90387E", color: "white" }}
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              Save Profile
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

// Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
