import React, { Fragment, Component } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Styles/tutorial.css";
import "intro.js/introjs.css";

// Components
import SinglesNavigation from "./Components/Navigation/SinglesNavigation";
import ScrollTop from "./Components/ScrollTop";
import Application from "./Components/Application";
// Pages
import MainPage from "./Pages/MainPage";
import MatchesPage from "./Pages/MatchesPage";
import MatchMadePage from "./Pages/MatchMadePage";
import SinglesPage from "./Pages/SinglesPage";
import SinglesProfile from "./Pages/SinglesProfile";
import AddSingle from "./Pages/AddSingle";
import ProfilePage from "./Pages/ProfilePage";
import NotFoundPage from "./Pages/404";
import SuccessSignUp from "./Pages/SuccessSignUp";
import FeedbackPage from "./Pages/FeedbackPage";
import { Provider } from "react-redux";
import { store } from "./state/store";

const Singles = () => (
  <Fragment>
    <SinglesPage />
  </Fragment>
);

const SinglesApplication = () => (
  <Fragment>
    <SinglesProfile />
  </Fragment>
);

const AddSinglePage = () => (
  <Fragment>
    <AddSingle />
  </Fragment>
);

const SingleProfilePage = () => (
  <Fragment>
    <SinglesProfile />
  </Fragment>
);

const Matches = () => (
  <Fragment>
    <MatchesPage />
  </Fragment>
);

const MatchMade = () => (
  <Fragment>
    <MatchMadePage />
  </Fragment>
);

const Profile = () => (
  <Fragment>
    <ProfilePage />
  </Fragment>
);

const Feedback = () => (
  <Fragment>
    <FeedbackPage />
  </Fragment>
);

const SignInPage = () => (
  <Fragment>
    <Application />
  </Fragment>
);
const SignUpPage = () => (
  <Fragment>
    <Application />
  </Fragment>
);

const SingleLoginPage = () => (
  <Fragment>
    <SinglesProfile />
  </Fragment>
);

const NotFound = () => (
  <Fragment>
    <NotFoundPage />
  </Fragment>
);
const SuccessSignUpPage = () => (
  <Fragment>
    <SuccessSignUp />
  </Fragment>
);

class App extends Component {
  render() {
    Aos.init();
    return (
      <Provider store={store}>

        <Router>
          <SinglesNavigation />
          <ScrollTop />
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/singles" component={Singles} />
            <Route
              exact
              path="/singles-profile"
              component={SinglesApplication}
            />
            <Route exact path="/add-single" component={AddSinglePage} />
            <Route exact path="/single-profile" component={SingleProfilePage} />
            <Route exact path="/matches" component={Matches} />
            <Route exact path="/match-made" component={MatchMade} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/add-matchmaker" component={Profile} />
            <Route exact path="/sign-in" component={SignInPage} />
            <Route
              exact
              path="/single-sign-up/:uid"
              component={SingleLoginPage}
            />
            <Route exact path="/sign-up" component={SignUpPage} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/success" component={SuccessSignUpPage} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
