import React from "react";
import { withRouter } from "react-router-dom";
import {
  setIsSingle,
  setUid,
  setSingleUid,
  setAccountCreated,
  reset,
} from "../state/app-slice";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { Button, makeStyles } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: theme.palette.common.white,
    margin: 0,
    width: "25%",
    height: "45px",
  },
}));

const LoginProfile = (props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const appState = useAppSelector((state) => state.app);
  const { uid, displayName, userEmail } = useAppSelector((state) => state.app);

  axios
    .post(process.env.REACT_APP_APILINK + "checkDocument", {
      path: "Shadchans",
      docId: uid,
    })
    .then((res) => res.data)
    .then((data) => {
      if (data.status === 200) {
        dispatch(setAccountCreated(true));
        document.getElementById("sign-button").innerHTML =
          "Hello " + displayName;
      } else {
        axios
          .post(process.env.REACT_APP_APILINK + "checkDocument", {
            path: "Singles",
            docId: uid,
          })
          .then((res) => res.data)
          .then(function (data) {
            if (data.status === 200) {
              dispatch(setIsSingle(true));
              dispatch(setSingleUid(uid));
              dispatch(setUid(""));
              props.history.push("/singles-profile");
            } else {
              window.alert("Please complete your application");
              props.history.push("/Add-Matchmaker");
            }
          });
      }
    })
    .catch((e) => {
      console.log(`There was an error -- ${e}`);
    });
  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
        <img
          src={`${appState.photoUrl}?time=${new Date().getTime()}}`}
          className="img-responsive img-thumbnail mx-auto"
          style={{
            marginTop: "0px",
            height: "200px",
            width: "200px",
          }}
          alt="Profile Pic"
          id="profile-image"
        />
        <div
          className="md:pl-4"
          style={{ textAlign: "center", margin: "auto" }}
        >
          <h1 className="text-2xl font-semibold">
            Welcome to imashadchan.com!
          </h1>
          <br />
          <br />
          <h2 className="text-2xl font-semibold">You are signed in as</h2>

          <h2 className="text-2xl font-semibold">{displayName}</h2>
          <h3 className="italic">{userEmail}</h3>
        </div>
      </div>
      <div className="text-center">
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(reset());
            document.getElementById("sign-button").innerHTML = "Sign In";
          }}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default withRouter(LoginProfile);
