import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { useHistory } from "react-router-dom";
import { Add, Settings, FileCopyOutlined } from "@material-ui/icons";
import { Button, TextField } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import {
  setIsSinglesPageTutorial,
  setIsTutorial,
  setSingleUid,
  setSinglesPageLoaded,
} from "../state/app-slice";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const SinglesPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appState = useAppSelector((state) => state.app);
  const [rows, setRows] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const steps = [
    {
      target: "#singlesNavText",
      content: "Click here to see your list of singles",
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Your Singles",
      disableScrolling: true,
    },
    {
      target: "#add-singles",
      content: (
        <div>
          There are two ways to add your Singles:
          <br />
          1) Add your Single manually
          <br />
          2) Let your Single add themselves via a link
        </div>
      ),
      title: "Adding Singles",
      placement: "bottom",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableScrolling: true,
    },
    {
      target: "#tableRow",
      content: "Click here to edit or view a single's profile",
      placement: "bottom",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Viewing A Single's Profile",
    },
    {
      target: "#matchesNavText",
      content: "Click here to view your list of matches",
      placement: "bottom",
      disableBeacon: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Viewing Your Matches",
    },
  ];

  if (appState.isMobile) {
    steps.shift();
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      dispatch(setIsSinglesPageTutorial(false));
      dispatch(setIsTutorial(false));
      setStepIndex(0);
      if (action === ACTIONS.CLOSE) {
        return;
      }
      history.push("/matches");
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  useEffect(() => {
    if (!appState.uid || appState.uid === "") {
      window.alert("You are not signed in");
      history.push("/");
    } else if (!appState.accountCreated) {
      window.alert("Please complete your application");
      history.push("/Add-Matchmaker");
    } else {
      axios
        .post(process.env.REACT_APP_APILINK + "getListOfSingles", {
          uid: appState.uid,
        })
        .then(function (response) {
          const newRows = response.data.items.map((item) => ({
            firstName: item.firstName,
            lastName: item.lastName,
            number: item.cellPhone,
            link: item.id,
          }));
          setRows(newRows);
          setIsActive(false);
        });
      document.getElementById("link").value =
        window.location.protocol +
        "//" +
        window.location.host +
        "/single-sign-up/" +
        appState.uid;
    }
    return () => {
      dispatch(setSinglesPageLoaded(false));
    };
  }, [history]);

  const copyTextFunc = () => {
    const copyText = document.getElementById("link");
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        alert("Copied the text: " + copyText.value);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const openSingle = (id) => {
    dispatch(setSingleUid(id));
    history.push("/singles-profile");
  };

  const openNew = () => {
    history.push("/singles-profile");
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading your content...">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={appState.isSinglesPageTutorial}
        scrollToFirstStep
        locale={{
          last: "Next",
        }}
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            primaryColor: "#90387E",
          },
        }}
      />
      <div>
        <div
          className="page-content"
          id="singles-page"
          data-aos="fade-down"
          data-aos-duration="1500"
        >
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container container-fluid text-center">
              <div className="w-full flex justify-end">
                <div id="add-singles">
                  <Button
                    id="generate-link"
                    variant="contained"
                    size="large"
                    data-toggle="modal"
                    data-target="#copyLinkModal"
                    style={{
                      marginBottom: "15px",
                      backgroundColor: "#90387E",
                      color: "white",
                    }}
                    endIcon={<Settings />}
                  >
                    Generate Link
                  </Button>
                  <Button
                    id="manual-add"
                    onClick={openNew}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    style={{
                      marginBottom: "15px",
                      marginLeft: "10px",
                      backgroundColor: "#90387E",
                      color: "white",
                    }}
                    endIcon={<Add />}
                  >
                    Add Single
                  </Button>
                </div>
              </div>

              <table
                className="table table-hover table-striped"
                cursor="pointer"
              >
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, idx) => (
                    <tr
                      id="tableRow"
                      key={`${row.firstName} ${row.lastName} - ${idx}`}
                      className="clickable-row cursor-pointer"
                      onClick={() => openSingle(row.link)}
                    >
                      <td>
                        <a>{row.firstName}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row.lastName}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row.number}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="copyLinkModal"
        tabIndex="-1"
        aria-labelledby="copyLinkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="copyLinkModalLabel">
                Click button to copy link to clipboard.
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row" style={{ textAlign: "center" }}>
                  <TextField
                    type="text"
                    id="link"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="form-group col-md">
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  data-dismiss="modal"
                  style={{
                    width: "100%",
                    borderColor: "#90387E",
                    color: "#90387E",
                  }}
                >
                  Close
                </Button>
              </div>
              <div className="form-group col-md">
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={copyTextFunc}
                  style={{
                    width: "100%",
                    backgroundColor: "#90387E",
                    color: "white",
                  }}
                  component="span"
                  startIcon={<FileCopyOutlined />}
                >
                  Copy
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default SinglesPage;
